.signup-modal-shadow{
  position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(140px);
        z-index: 1001; /* Ensure the clipRule is above other elements */

}

.signup-modal{
  width: 400px;
  max-width: calc(100% - 40px - 40px);
  height: fit-content;
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.03);
  border-left: transparent;
  background: rgba(21, 24, 30, 0.6);
  z-index: 1002;
  padding: 30px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  p{
    margin: 0;
  }
  .texts{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .title{
      font-size: 25px;
      font-weight: 600;
      color: white;
    }
    .description{
      color: white;
      opacity: 0.9;
    }
  }
  .buttons{
    display: flex;
    gap: 16px;
    button{
      cursor: pointer;
      border-radius: 10px;
      border: none;
      padding: 10px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      p{
        font-size: 16px;
        font-weight: 500;
      }
      svg{
        height: 18px;
      }
    }
    .btn-primary{
      background-color: #0085FF;
      color: white;
    }
    .btn-secondary{
      background-color: rgba(255, 255, 255, 0.9);
      color: black;
    }
  }
}