@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Satoshi";
  background-color: black;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  //city colors
  --ferrara: #eb81a9;
  --modena: #f8be09;
  --forli: #6ac5e9;
  --piacenza: #ea6953;

  --ferrara-border: rgba(0, 0, 0, 0.2);
  --modena-border: rgba(0, 0, 0, 0.2);
  --forli-border: rgba(0, 0, 0, 0.2);
  --piacenza-border: rgba(0, 0, 0, 0.2);

  --black: rgba(0, 0, 0, 0.85);
  --blue-100: #0085ff;

  // borders
  --midnight-border-100: rgba(52, 52, 52, 0.73);
  --midnight-wash-40: rgba(41, 45, 53, 0.4);
}

@font-face {
  font-family: "Satoshi";
  src: url("./assets/fonts/Satoshi-Variable.woff2") format("woff2"),
    url("./assets/fonts/Satoshi-Variable.woff") format("woff"),
    url("./assets/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

//mobile

@media screen and (max-width: 480px) {
  :root {
    --margin: 16px;
    --col: calc((100vw - 2 * var(--margin) - 20px * 3) / 4);
    // --col: min(72px, calc((100% - 2 * var(--margin) - 20px * 11) / 12 ));
  }
}

//tablet

@media screen and (max-width: 768px) and (min-width: 481px) {
  :root {
    --margin: 50px;
    --col: calc((100vw - 2 * var(--margin) - 20px * 7) / 8);
    // --col: min(72px, calc((100% - 2 * var(--margin) - 20px * 11) / 12 ));
  }
}

@media screen and (max-width: 1279px) and (min-width: 769px) {
  :root {
    --margin: 67px;
    --col: calc((100vw - 2 * var(--margin) - 20px * 7) / 8);
    // --col: min(72px, calc((100% - 2 * var(--margin) - 20px * 11) / 12 ));
  }
}

//desktop

@media screen and (min-width: 1280px) {
  :root {
    --margin: 98px;
    --col: calc((100vw - 2 * var(--margin) - 20px * 11) / 12);
    // --col: min(72px, calc((100% - 2 * var(--margin) - 20px * 11) / 12 ));
  }
}

::-webkit-scrollbar {
  display: none;
}

.blured {
  &:before {
    content: "";
    position: absolute;
    bottom: 120px;
    left: 3px;
    right: 3px;
    top: 3px;
    opacity: 0.97;
    backdrop-filter: blur(75px);
    border-radius: 20px;
    mask: linear-gradient(to top, transparent 40%, rgb(0, 0, 0) 85%);
    transition: 1s;
  }

  &:after {
    //top blur
    content: "";
    position: absolute;
    bottom: 3px;
    left: 3px;
    right: 3px;
    top: 0px; /* Adjust the position as needed */
    opacity: 0.96;
    backdrop-filter: blur(75px);
    border-radius: 20px;
    mask: linear-gradient(to bottom, transparent 60%, rgb(0, 0, 0) 85%);
    transition: 1s;
  }
}

.bot-blur {
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0px;
    left: 0px;
    // right: 3px;
    opacity: 0.97;
    backdrop-filter: blur(75px);
    // filter: blur(75px);
    border-radius: 15px;
    mask: linear-gradient(to bottom, transparent 40%, rgb(0, 0, 0) 85%);
    width: calc(100% + 2px);
    height: calc(100% - 2 * 3px);
    // background-color: red;
  }
}

.right-blur {
  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0px;
    left: 0px;
    // right: 3px;
    opacity: 0.97;
    backdrop-filter: blur(75px);
    // filter: blur(75px);
    border-radius: 15px;
    mask: linear-gradient(to left, transparent 40%, rgb(0, 0, 0) 85%);
    width: calc(100% + 2px);
    height: calc(100% - 2 * 3px);
    // background-color: red;
  }
}

.blurred0px {
  //bottom blur
  &:before {
    content: "";
    position: absolute;
    bottom: 120px;
    left: 3px;
    // right: 3px;
    top: 3px;
    opacity: 0.97;
    backdrop-filter: blur(75px);
    // filter: blur(75px);
    border-radius: 12px;
    mask: linear-gradient(to top, transparent 40%, rgb(0, 0, 0) 85%);
    transition: 1s;
    width: calc(100% - 5px);
    height: calc(100% - 2 * 3px);
  }

  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0px;
  //   left: 0px;
  //   right: 0px;
  //   top: 0px; /* Adjust the position as needed */
  //   opacity: 0.96;
  //   backdrop-filter: blur(75px);
  //   border-radius: 20px;
  //   mask: linear-gradient(to bottom, transparent 60%, rgb(0, 0, 0) 85%);
  //   transition: 1s;
  // }
}
//ROBERT
// .blurredHomepage {
//   //top blur
//   &:before {
//     content: "";
//     position: absolute;
//     // bottom: 100px;
//     left: 0px;
//     right: 0px;
//     top: 0px;
//     opacity: 0.97;
//     backdrop-filter: blur(75px);
//     border-radius: 20px;
//     mask: linear-gradient(to top, transparent 30%, rgba(0, 0, 0, 0.884) 85%);
//     transition: 1s;
//     height: 200px;
//     // width: calc(100% - 6px);
//   }
//   //bottom blur
//   &:after {
//     content: "";
//     position: absolute;
//     bottom: 0px;
//     left: 0px;
//     right: 0px;
//     top: 0px; /* Adjust the position as needed */
//     opacity: 0.96;
//     backdrop-filter: blur(75px);
//     border-radius: 20px;
//     mask: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.863) 75%);
//     transition: 1s;
//     height: 100%;
//   }
// }

.fit {
  width: calc(100% - 2 * var(--margin));
  margin: auto;
}

.full {
  width: 100%;
}

@media screen and (min-width: 1280px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  .grid > div:nth-child(n + 3) {
    display: none;
  }
  .grid-2 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media screen and (max-width: 767px) {
  .grid-2 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.col-space {
  height: var(--col);
}

.container-w-titlepad-white {
  background-color: white;
}

.container-w-titlepad {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: max(68px, var(--col));
  //
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //
  .titlepad {
    position: absolute;
    top: max(20px, calc(var(--col) - 18px - 36px));
    left: calc(var(--margin) + 20px);
    //
    margin: 0;
    display: inline-flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.28px;
    font-family: "Satoshi";
  }
  .titlepad-color {
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.8);
  }
  .titlepad-dark {
    background: rgba(36, 41, 48, 0.6);
    color: rgba(255, 255, 255, 0.8);
  }
  .titlepad-light {
    color: #56607b;
    background: #f2f4f9;
  }
}

.sectionSpacer {
  height: var(--col);
}

.sectionSpacer-white {
  background-color: white;
}

.open-modal {
  overflow-y: hidden;
}
